export default {
  program: {
    programName: "",
    code: "",
    id: "",
    questionnaireUUID: "",
  },
  additionalOrderEntryQuestions: "",
  testSectionId: "",
};
