const ReferredOutTestsFormValues = {
  labNumberInput: "",
  selectedPatientId: "",
  startDate: "",
  endDate: "",
  dateType: "",
  dateOfBirth: "",
  searchTypeValues: ["TEST_AND_DATES", "LAB_NUMBER", "PATIENT"],
};

export default ReferredOutTestsFormValues;
