const SearchPatientFormValues = {
  labNumber: "",
  patientId: "",
  lastName: "",
  firstName: "",
  dateOfBirth: "",
  guid: "",
  gender: "",
  suppressExternalSearch: true,
};

export default SearchPatientFormValues;
