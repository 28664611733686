import React from "react";

function Footer() {
  return (
    <footer className="footer py-2 mt-auto">
      <p className="m-0 text-center">
        {/* © OpenElis Global2 2020. All rights reserved. */}
      </p>
    </footer>
  );
}

export default Footer;
